import Vue from 'vue';
import Vuex from 'vuex';
import REST_interface from "@/REST_interface";
import router from '@/router/index'
import moment from "moment";
moment.locale('de-ch')

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        reLoginActive: false,
        user: {
            isLoggedIn: false,
            username: "",
            role: "",
            expiresAt: "",
        },
        error: "",
        events: [],
        persons: [],
        eventNames: [
            "Jugendtreff L'Dorf",
            "Jugendtreff L'Dorf Kids",
            "Jugendtreff Bellach",
            "Jugendtreff Bellach Kids",
            "FTM L'Dorf",
            "FTM Kids L'Dorf",
/*             "FTM Rüttenen",
            "FTM Kids Rüttenen",
            "FTM Oberdorf",
            "FTM Kids Oberdorf", */
            "FTM Bellach",
            "FTM Kids Bellach",
/*             "Spielnami L'Dorf", */
            "Spielnami Rüttenen",
            "Spielnami Oberdorf",
            "Spielnami Bellach",
            "Mobile Arbeit L'Dorf",
            "Mobile Arbeit Rüttenen",
            "Mobile Arbeit Oberdorf",
            "Mobile Arbeit Bellach",
/*             "PaLe L'Dorf",
            "PaLe Rüttenen",
            "PaLe Oberdorf", */
            "Veranstaltung L'Dorf",
            "Veranstaltung Bellach",
        ],
        places: [
            'Langendorf',
            'Oberdorf',
            'Bellach',
            'Rüttenen',
            'Solothurn',
            'Bettlach',
            'Selzach',
            'Lommiswil',
            'Andere',
        ],
    },
    mutations: {
        setUser(state, data) {
            state.user.username = data.user[0].username;
            state.user.role = data.user[0].role;
            state.user.expiresAt = data.expiresAt
            state.reLoginActive = false;
        },
        removeUser(state) {
            state.user = {},
            localStorage.removeItem("user");
        },
        setEvents(state, events) {
            state.events = events
        },
        setPersons(state, persons) {
            state.persons = persons
        },
        error(state, err){
            state.error = err;
        }
    },
    actions: {
        async login({ commit, dispatch }, user) {
            let answer = false;
            try {
                let { data } =  await REST_interface.login(user)
                localStorage.setItem('user', JSON.stringify(data));
                await dispatch('fetchEvents');
                await dispatch('fetchPersons');
                commit('setUser', data);
                answer = true
                router.replace('/');
            } catch(err){
               console.error(err); 
            }
            return answer    
        },

        logout({commit}){
            commit('removeUser')
        },

        async fetchEvents({ commit }) {
            try {
                const { data } = await REST_interface.getCollection("events");
                data.forEach(element => {
                    element.name = element.event.name;
                    element.eventDate = new moment(element.event.eventDate).format('LL');
                    element.sortDate = new Date(element.event.eventDate).getTime();
                    element.place = element.event.place;
                    element.participants = element.event.participants.length
                    element.comments = element.event.comments
                });
                commit('setEvents', data);
            } catch(err){
                throw Error(err);
            }
        },

        async fetchPersons({ commit }) {
            try {
                const { data } = await REST_interface.getCollection("persons");
                commit('setPersons', data);
            } catch(err){
               throw Error(err);
            }
        },
    },
    getters: {
        isAdmin: state => {
            return state.user.role == "Admin"
        },
        getEvents: state => {
            return state.events
        },
        getPersons: state => {
            return state.persons
        },
        getEventNames: state => {
            return state.eventNames
        },
        getPlaces: state => {
            return state.places
        },
    },
});